import { Helmet } from 'react-helmet';

export const ChatboxHeaders = () => {
  const title = 'Marja AI - Ask an AI trained on Ayatollah Shia rulings';
  const description =
    'Get answers to your questions. Ask an AI trained on Ayatollah Sistani and other Shia rulings. Reliable guidance and explanations on religious matters.';
  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
};
